<template>
<div class='inputWrapper'>
    <span class='span25'>
    <input type="time" min="00:00:00" :class="disabled?'disabled':''" max="48:59:59" step="1" :disabled="disabled" @keypress.enter="enterPressed()" @focus="$event.target.select()" v-model="internalValue"/>
    </span>
    <span class='span75'>
    <clipboard v-model="internalValue"><icon-write /></clipboard>
    </span>
</div>
</template>
<script>
import clipboard from '@/components/Clipboard';

export default {
  props: {
    value: Number,
    disabled: Boolean
  },
  components : {
    clipboard
  },
  methods: {
      enterPressed() { this.$emit("enter"); },
      fmt2( aTime) {
      	if ( aTime < 10 ) 
      	{
      		return "0"+aTime;
      	}
      	return ""+aTime;
      }
  },
  computed: {
    internalValue: {
      get() {
        let ss = this.value % 60;
        let mm = ((this.value - ss) / 60 ) % 60;
        let hh = ((this.value - ss - mm * 60) / 3600 ) % 60;
        let v = hh+":"+this.fmt2(mm)+":"+this.fmt2(ss);

        return v;
      },
      
      set(v) {
        let arr = v.split(":");
        let val = 0;
        if ( arr )
        {
        	if ( arr.length >= 1 )
        	{
        		val = parseInt(arr[0]) * 3600;
        	}
        	if ( arr.length >= 2 )
        	{
        		val += parseInt(arr[1]) * 60;
        	}
        	if ( arr.length >= 3 )
        	{
        		val += parseInt(arr[2]);
        	}
        }
        
        this.$emit("input", val)
      }
    }
    }
 }
</script>
<style lang="scss" scoped>
input { font-size: 12pt; border: none; width: 100%}
input:disabled { font-size: 11pt; border: none; width: 100%; color: #aaa !important; }
.disabled { font-size: 11pt; border: none; width: 100%; color: #aaa !important; }
input:focus { outline: 1pt dotted grey;}
.span25 { display: inline-block; width: 85%}
.dist5 { margin-left: 4pt; }
.span75 {  width: 15%; text-align: right; float: right; }
.inputWrapper { 
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    width: 100%;
}

.inputButton { 
    border: 1pt solid grey;
}

</style>