<template>
 <div :style="getAppStyle()+'width: 100%; height: 100%; position: relative;'">
   
     <div class='transition' :style='getAppStyle()+getControlStyle()'>
    	<!-- CONTROLS -->
    	    
		    <div style='float: top; width: 100%; vertical-align: middle;'>
		        <div style='float: left; width: 90%; vertical-align: middle; text-align: center; font-size: 14pt; font-weight: bold;'>GRID VIEWER 1.0</div>
		       
		        
		        <div style='vertical-align: middle; text-align: center; font-size: 11pt; font-weight: bold;width: 90%;'>CONTROLS</div>
		        <br/>
		        <div v-if="station && station.logo" 
			         >
			         <center>
			         <GImage :data="getImgData( station.logo)"/>
			         </center>
			    	
			     </div> 
		        <br/>
			    <span class='SVcontrolLabel'>Channel/Station:</span><br/>
			    <!--
			    <select style='width:100%' class=' select' v-model="stationId" @change='getInventory'>
			          <option class='menu-item' v-for="(x) in stations" :key="'ch_'+x.id" :value="x.id" v-bind:selected="x.id === stationId" >
			           <div class='menu-item'> {{ x.label}} [{{ x.id}}]</div>
			          </option>
			    </select>
			    
			    -->
			    <GSelect placeholder='choose channel' :options="stations" style='width:100%' v-model="selectedStation" @input='getInventory'>
			          
			    </GSelect>
			    
			    
			    <br/>
			    <InputDatePick :auto="false" 
                            @change="dateChanged" v-model="activeDate"/>     

		        <br/>
			    <span class='SVcontrolLabel'>2nd Timezone:</span><br/>
			    <GSelect placeholder='choose second timezone' :options="timezones" style='width:100%' v-model="selectedTimezone" @input='getInventory'>
			          
			    </GSelect>
			    
			    <br/>
			    <span class='SVcontrolLabel'>Unit: (in min)</span><br/>
			     <!--
			    <select style='width:100%' class=' select' v-model="unit" @change='setValues(dayStartAt)'>
			          <option class='menu-item' v-for="(x) in unitArr" :key="'unit_'+x.id" :value="x" v-bind:selected="x === unit" >
			           <div class='menu-item'> {{ x }}min</div>
			          </option>
			    </select>
			   
			    -->
			    <vue-slider @change='val => unitChanged(val)' :data=unitArr :marks=true :min='5' :max='60' v-model="unit"></vue-slider>
			    
			    <br/>
			    <span class='SVcontrolLabel'>Zoom: ({{zoom}})</span>
			    <vue-slider :min='10' :max='60'  v-model="zoom"></vue-slider>
		    	<br/>
		    	
		    	<table width=100%>
		    	
		    	<tr><td>
		    	<span class='SVcontrolLabel'>Colouring</span>
		    	</td><td>
		    	<!-- 
		    	<app-switch v-model="colourCAT" :checked="colourCAT"/>
		    	 -->
		    	<button v-tooltip="'Ratecard'" :class="'button mini '+(colourCAT=='ratecard'?'selected':'')" :style='getAppStyle()' @click="colourCAT='ratecard'">RC</button>
		    	<button v-tooltip="'Zones'" :class="'button mini '+(colourCAT=='zone'?'selected':'')" :style='getAppStyle()' @click="colourCAT='zone'">Z</button> 
		    	<button v-tooltip="'Daypart'" :class="'button mini '+(colourCAT=='daypart'?'selected':'')" :style='getAppStyle()' @click="colourCAT='daypart'">DP</button> 
		    	<button v-tooltip="'Program-Category'" :class="'button mini '+(colourCAT=='category'?'selected':'')" :style='getAppStyle()' @click="colourCAT='category'">Cat</button>  
		    	<button v-tooltip="'no colouring'" :class="'button mini '+(colourCAT=='off'?'selected':'')" :style='getAppStyle()' @click="colourCAT='off'">-</button>  
		    	
		    	</td></tr>
		    	<tr><td>
		    	<span class='SVcontrolLabel'>Show break-structure:</span>
		    	</td><td>
		    	<app-switch v-model="withAds" :checked="withAds" @change='showBreaks()'/>
		    	</td></tr>
		    	<tr><td>
		    	<span class='SVcontrolLabel'>Show Controls: </span>
		    	</td><td>
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
		    	</td></tr>
		    	<tr><td>
		    	<span class='SVcontrolLabel'>Show used Ratecards:</span>
		    	</td><td>
		    	<app-switch v-model="showRC" :checked="showRC" @change='showRCs()'/>
		    	</td></tr></table>

		    	<div v-for="(rcIdx,idx) in rateCards" :key="'ratec_'+idx" class='transitionFast' :style='getPGStyle(rcIdx)'>
		    	    <a @click="openEntity(event, 'RateCard', rcIdx.id)">{{ rcIdx.name }}:&nbsp;&nbsp;{{Number(rcIdx.price).toLocaleString(myLocale())}} {{currency.shortname}}</a>
		    	</div>
		    	
		    	
		   
		    	
		    	<br/><br/>
		    	<button class='button' :style='getAppStyle()' v-tooltip="'Reload all data'" @click="getInventory()"><mdicon :width="16" name="reload" /></button>
		    	
		    </div> <!-- CONTROLS -->
    </div>
    
    <!-- MAIN -->
	<div class='transition' :style="getAppStyle()+'float: left; width: 77%;'"> 
	    <!-- HEADER -->
	    <div style='float: top; width: 100%; height: 28pt; overflow-y: scroll;'>
		    <div style='float: top;'>
			    <div style='float: left; width: 6%;'>
			       <div class='header'>
			       		{{station.shortname}}&nbsp;{{tz.shortname}}
			       	</div>
			    </div>
		    </div>
		    <div class='transition' :style='timezoneStyle()'>
		       	<div class='header'>
		       		<div class='transition' style='font-weight: bold;'>
		       		{{getLocalTZ().shortname}}
		       		</div>
		    	</div>
	    	</div>
	    	<!-- ALL WEEKDAYS -->
	    	<div :style='weekdayStyle()' class="transition"  v-for="(day,idx) in weekDayIndexes()" :key="'day_'+idx">
	        	<div class='headerDay' style='width: 100% !important; position: relative;'>
	          		<b>{{weekdayLabel[day]}}</b>
	          		{{printDate(dateArr[day])}}
	          		<div style='position: absolute; right: 0pt;'>
	          		<button title='Download breakstructure' class='button'
	          		    @click="downloadDay({date: day, dateISO: dateArr[day], day})" 
	          		    style='height: 16pt; width: 16pt; background-color: transparent; border: 1px solid #ccc;'>
	          		    <mdicon size='14' name='download'></mdicon></button>
	          		</div>
	          		
	        	</div>
	    	</div>
	  	</div>
	    <!-- HEADER -->
	    
	    <!-- TABLE -->
	    <div style='color: #000; width: 100%; height: calc(100vh - 144px); overflow-y: scroll;'         
						  @dragover.prevent
						  @dragenter.prevent
						  @contextmenu.prevent
	        >
	    	<div style='float: top; width: 100%;height: 100%; width: 100%;' ref='mainSchedule' >
	    		<div id='timeLine' style='float: left; width: 6%; color: #444; background-color: #eee;' @mouseover="setDay(0);">
	    			<!-- print time channel TZ -->
	       			<div class='time' :style='mainTime(time)' v-for="(time,idx) in grid" :key="'time_'+idx">
	        			<b>{{printTime( time)}}</b>
	      			</div>
	      			
	    		</div> 
	   
	    		<div class='transition' :style='timezoneStyle()' @mouseover="setDay(0);" >
	    		    <!-- print time 2nd TZ -->
	    		    
	       			<div class='time' :style='gridHeight()' v-for="(time,idx) in grid" :key="'time2_'+idx">
	        			<b>{{printTime2( time+diff)}}</b>
	       			</div>

	    		</div>
		    	<!-- ALL WEEKDAYS -->
		    	<div :style='weekdayStyle()' class="transition" v-for="(day,idx) in weekDayIndexes()" :key="'wday_'+idx">
		        	<div  @mouseover="setDay(day);"
		        	      @drop.exact="onDropGrid($event, day, false)"
		        	      @drop.shift="onDropGrid($event, day, true)"
	             		  @drop.ctrl="onDropGridCopy($event, day)"
	             		  @contextmenu.prevent="nodeRClicked($event, dateArr[day], day)"
					      :style="weekdaySlotStyle(zoom)">
		        		<span style='color: transparent;' >{{timeEnd}}</span>
		        		
		        		<div v-for="(grd,idxg) in gridElements[day] " :title='grd.detail.name' class='gridMain' :style='gridStyle(grd)' :key="'g_'+idx+'.'+idxg" 
				             @click="showGrid($event, grd, dateArr[day], day)"
				             draggable 
		           			 @dragstart.exact="startDragGrid($event, grd)"
		           			 @dragover.prevent
						     @dragenter.prevent
						     @mouseover="setGrid(grd, dateArr[day])"
						     @mouseout="setGrid({noMenu:false})"
				             @contextmenu="nodeRClicked($event, dateArr[day], day)">
		           			<div 
		           			     style='line-height: 1; position: relative;'>
					           	<span :style='gridTitleStyle(grd, dateArr[day])' >
					           	<b>{{ getName(grd, dateArr[day]) }}</b>
					           
					           	</span>
					           	<span v-if="withAds" :style='gridTitleStyle(grd)'>{{printTime( grd.durationInSec)}} // {{printTime( grd.adDurationInSec)}}</span>
					           	<span v-else :style='gridTitleStyle(grd)'>{{printTime( grd.detail.starttime)}}</span>
					           	<div v-if="grd.rateCard">
					           	<span :style='gridTitleStyle(grd)'>PG-{{grd.detail.pricegroup}} ({{Number(grd.rateCard.price).toLocaleString(myLocale())}}{{currency.shortname}})</span>
					           	</div>
					           	<div v-for="(adspace,idxa) in adSpaces(grd, dateArr[day], day)" :style='adStyle(grd, adspace)' class='adMain' :key="'g_'+idx+'.'+idxg+'.'+idxa" :title='adLabel(adspace)' >
		           					{{adLabel(adspace)}}
		           				</div> 
		           			</div>
		           
		        		</div>
		        	</div>
		    	</div> <!-- ALL WEEKDAYS -->
	    	</div> 
    	</div>  <!-- TABLE -->
    </div>  <!-- MAIN -->

    
    <ContextMenu ref="menu" :offsetY="-100">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem  v-if="contextData.grid.detail" @clicked="nodeToOpen($event, contextData.grid.detail.id)">
          Open {{metadata.name}}: {{ contextData.grid.detail.name }} 
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.grid.detail" @clicked="showGrid($event, contextData.grid, contextData.dateISO, contextData.dayOfWeek)">
          Visualize Breaks: {{ contextData.grid.detail.name }} 
        </ContextMenuItem>
        <ContextMenuSep v-if="contextData.grid.detail"/>
        <ContextMenuItem v-if="contextData.grid.detail" @clicked="showAdvertising($event, contextData.grid)">
          Show Advertising for {{ contextData.grid.detail.name }} 
        </ContextMenuItem>
        <ContextMenuSep v-if="contextData.grid.detail"/>
        <ContextMenuItem  v-if="contextData.grid.detail && contextData.replace" @clicked="$refs.menu.close(); deleteRepl({grid: contextData.grid.detail, date: contextData.date, dateISO: contextData.dateISO, repl: contextData.replace})">
          Delete Replacement '{{contextData.replace.name}}' 
        </ContextMenuItem>
        <ContextMenuItem  v-if="contextData.grid.detail && contextData.replace" @clicked="$refs.menu.close(); editRepl({grid: contextData.grid.detail, date: contextData.date, dateISO: contextData.dateISO, repl: contextData.replace})">
          Edit Replacement '{{contextData.replace.name}}' 
        </ContextMenuItem>
        <ContextMenuItem  v-if="contextData.grid.detail && contextData.replace" @clicked="$refs.menu.close(); $refs.editor.open('GridDetailReplace', contextData.replace.id)">
          Open Replacement '{{contextData.replace.name}}' 
        </ContextMenuItem>
        <ContextMenuItem  v-if="contextData.grid.detail && !contextData.replace" @clicked="$refs.menu.close(); editRepl({grid: contextData.grid.detail, date: contextData.date, dateISO: contextData.dateISO, id: 0})">
          Create Replacement for {{contextData.date}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.grid.detail" @clicked="removeGrid($event, contextData.grid)">
          Remove Grid {{ contextData.grid.detail.name }} 
        </ContextMenuItem>
        <ContextMenuItem v-else @clicked="newGrid($event, contextData)">
          Create new Grid @  {{contextData.time}}
        </ContextMenuItem>
         <ContextMenuSep v-if="contextData.grid.detail"/>
        <ContextMenuItem  v-if="contextData.grid.detail" @clicked="$refs.menu.close(); runBreakCoding({date: contextData.date, dateISO: contextData.dateISO})">
          Run complete Break-Coding for {{contextData.date}}
        </ContextMenuItem>
        <ContextMenuSep v-if="contextData.grid.detail"/>
        <ContextMenuItem  @clicked="$refs.menu.close(); downloadDay({date: contextData.date, dateISO: contextData.dateISO})">
          Download {{contextData.date}}
        </ContextMenuItem>
        
       </template>
    </ContextMenu>
    
    <GridBreakView v-show='showBreakView' 
        :gridAdsInput=selectedGrid 
        :gridDetailId=selectedGrid.id 
        :replacement=useActiveReplacement
        :dayOfWeekBit=dayOfWeekBit
        :zones=zones
        @update="update"
        @updateReplacement="updateReplacement"
        :rateCards=rateCards
        :allRateCards=allRateCards
        :station=station :timezoneLocal=getLocalTZ() :timezoneStation=tz :currency=currency :diff=diff
        @close="closeGrid()">
    </GridBreakView>
    <GFWEOpenEditor ref='editor' :path=currentPath @update='passUpdate' @closeUntilPosition="closeUntilPosition" @close="close"></GFWEOpenEditor>
  
    <GFWEditorRouter v-show='showEditor' @update="update" @insert="update" @close="closeEditor" 
	    :selectedIndex="selectedIndex" 
	    :dataArray="allEntities" 
	    
	    :entityMap="entityMap"
	    :metadata="metadata" 
	    :dataId="dataId"
	    :mapping="mapping" 
	    :time="new Date().getTime()" 
	    :dataRecord="selectedRecord">
    </GFWEditorRouter>  
    
    <GridPopup v-show="openGRDPP" 
               @open='openGRDPP=true' 
               @close='openGRDPP=false'  
               @update="update"
               :zones=zones
               :name='"Add Grid-Element"' ref='completer'>
	
	</GridPopup>
	<GPrompt ref='prompt'/>
	<GConfirm ref='confirm'/>
	<ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
	<InfoPopUp :time=infoTime :infoType=asText :info=showAsText></InfoPopUp>
 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, gridAPI, setReload, myLocale, showError} from '@/variables.js';
import { getAppStyle, initAppMode, autoFG } from '@/AppStyle.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import ProgressBar from '@/components/ProgressBar';
import GImage from '@/components/GImage';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GridBreakView from '@/components/GridBreakView';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import GridPopup from '@/components/grid/GridPopup';
import GSelect from '@/components/misc/GSelect';
import GPrompt from '@/components/GPrompt';
import GConfirm from '@/components/GConfirm';
import InfoPopUp from '@/components/InfoPopUp';

import JQuery from "jquery";
import 'w3-css/w3.css';
let $ = JQuery;
export default {
  name: 'GFW Schedule View',
  components : {
   'app-switch': Switch, ContextMenu, ProgressBar, InputDatePick, ContextMenuItem, GSelect, GridBreakView, GridPopup, ContextMenuSep, InfoPopUp, GImage, GPrompt, GConfirm
  },
  data () {
    return {
      stationId: 0,
      station: {},
      tz: {},
      selectedTimezone: {},
      selectedStation: {},
      tz2: {},
      diff: 0,
      currency: {},
      stations: [],
      timezones: [],
      timezoneId: 0,
      zoomArr: [10,12,14,15,16,18,20,22,25,30,35,40,45,50,60],
      zoom: 10,
      unitArr: [3,5,6,10,12,15,20,25,30,45,60],
      unit: 15,
      colourCAT: 'ratecard',
      weekdayLabel: [],
      grid: [],
      header: {},
      gridElements: [],
      rateCards: [],
      allRateCards: [],
      dayStartAt: 0,
      activeDate: {},
      mapping: [],
      metadata: {},
      entityMap: {},
      dataId: 0,
      dayOfWeekBit: 0,
      selectedRecord: {},
      selectedIndex: 0,
      allEntities: [],
      showEditor: false,
      showBreakView: false,
      selectedGrid: {},
      withAds: false,
      showRC: false,
      controlsRight: false,
      activeGrid: {},
      activeDay: {},
      activeReplacement: null,
      useActiveReplacement: null,
      openGRDPP: false,
      dates: [],
      zones: [],
      editOpen: [],
      dateArr: [],
      asText: "",
      showAsText: "",
      infoTime: 0,
      pbAction: "",
      pbTitle: "",
      pbData: {},
      showProgressBar: false,
      getAppStyle,
      TZMetadata: {"name": "AccessPoint", "links":[{"name":"Timezone","entity":"Timezone","fromEntity":"AccessPoint","column":"timezone","references":"id","create":false,"editor":"LINK"}]}
    }
  },
  methods: {
    myLocale() { return myLocale();},
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 22%; height: calc(100vh - 120px); padding-right: 2ex;"
    	}
    	return "float: left; width: 22%; height: calc(100vh - 120px); padding: 2ex;"
    },
    // break vis function
    // color picker
    // color in table
    setTZ(label) {
    	this.getInventory();
    },
    showRCs() {
    },
    action( what, myId, api)
    {
   		this[what](myId, api)
    },
    setGrid(grd, day)
    {
    	if ( grd.replacements )
    	{
    		let repl = grd.replacements.find(p=>p.gridDetailId==grd.detail.id && p.date==day);
    		
    		this.activeReplacement = repl;
    	}
    	else
    	{
    		this.activeReplacement = null;
    	}
    	this.activeGrid = grd;
    },
    setDay( day)
    {
    	this.activeDay = day;
    },
    weekdaySlotStyle(zoom)
    { 
        let h = zoom*this.grid.length;
        return "border-left: 1px solid #eee; background-color: rgba(250,250,255, 1); position: relative; height: "+h+"pt !important; width: 100% !important;";
    },
    editRepl(data)
    {
    	//let d = date.year+"-"+date.monthValue+"-"+date.dayOfMonth;
    	
    	let repl = data.grid.gridReplacements && data.grid.gridReplacements.replacements ? data.grid.gridReplacements.replacements.find(p=>p.date==data.dateISO): null;
        let name = repl ? repl.name : data.grid.name;	  
    	let action = data.repl?"Edit":"Create";
    	let that=this;
    	this.$refs.prompt.confirm( {text: action + ' replacement for ' + data.date, title: data.grid.name, textField: name, button1: "Cancel", button2: "Create"}).then(x => {
    		let xdata = {name: x, date: data.dateISO };
    		HTTP.put( invAPI+"/editGridReplacement/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+data.grid.id, xdata)
            .then( response => 
            { 
                data.grid = response.data;
                alert(JSON.stringify( data.grid))
                that.update();
            
            }).catch(e => {
                    showError( that.$toast, "remove Grid", e);
              

            });
    	});
    	
    },
    createBreakDetails( data )
    {
    	let action = "Create/update break details ";
    	this.$refs.confirm.confirm( {text: action, title: this.selectedStation.label, button1: "Cancel", button2: "Create"}).then(x => {
        	
	    	this.pbData = data;
	        this.pbAction = "createBreakDetailsInternal";
	        this.pbTitle = "Create/update break details";
	 	    this.showProgressBar = true;
    	});
    },
    createBreakDetailsInternal( myId, api )
    {
    	let data = this.pbData;
    	let dateISO = data.dateISO;
    	let that=this;
    	HTTP.put( invAPI+"/createBreakDetails/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.selectedStation.id+"/"+data.dateISO+"/"+myId )
            .then( response => 
            { 
            	that.showProgressBar = false;
                that.update();
            
            }).catch(e => {
                    showError( that.$toast, "Run createBreakDetails", e);
                    that.showProgressBar = false;

            });
    },
    runBreakCoding( data )
    {
    	let action = "Run breakcoding on ";
    	this.$refs.confirm.confirm( {text: action + data.date, title: this.selectedStation.label, button1: "Cancel", button2: "Coding"}).then(x => {
        		
	    	this.pbData = data;
	        this.pbAction = "runBreakCodingInternal";
	        this.pbTitle = "Breakcoding";
	 	    this.showProgressBar = true;
    	});
    },
    downloadDay( data )
    {
    	let action = "download ";
    	let that=this;
    	//this.$refs.confirm.confirm( {text: action + data.date, title: this.selectedStation.label, button1: "Cancel", button2: "Download"}).then(x => {
    		let dateISO = data.dateISO;
        	
        	HTTP.post( invAPI+"/downloadBreakList/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.selectedStation.id+"/"+dateISO )
               .then( response => 
               { 
            	   let downloadResponse = response.data;
 	              
   	               that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
               
               }).catch(e => {
            	   
                   showError( that.$toast, "Run downloadBreakList", e);
               });
	    	
    	//});
    },
    forceFileDownload(base64, name) 
	{  
      const url = window.URL.createObjectURL(new Blob([this.base64ToArrayBuffer(base64)]));
      const link = document.createElement('a');
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    },
    base64ToArrayBuffer(base64) {
	    var binary_string = atob(base64);
	    var len = binary_string.length;
	    var bytes = new Uint8Array(len);
	    for (var i = 0; i < len; i++) {
	        bytes[i] = binary_string.charCodeAt(i);
	    }
	    return bytes.buffer;
	},
    runBreakCodingInternal( myId, api )
    {
    	let dateISO = this.pbData.dateISO;
    	let action = "Run breakcoding on ";
    	let that=this;
    	api.post( invAPI+"/breakCodingDay/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.selectedStation.id+"/"+dateISO+"/"+myId )
           .then( response => 
           { 
        	   that.showProgressBar = false;
               that.update();
           
           }).catch(e => {
        	   that.showProgressBar = false;
               showError( that.$toast, "Run breakcoding", e);
             

           });
    },
    deleteRepl(data)
    {
    	//let d = date.year+"-"+date.monthValue+"-"+date.dayOfMonth;
    	let name = data.repl?data.repl.name:data.grid.name;
    	let action = data.repl?"Delete":"??";
    	let that=this;
    	this.$refs.confirm.confirm( {text: action + ' replacement for ' + data.date, title: data.grid.name, button1: "Cancel", button2: "Delete"}).then(x => {
    		let xdata = {date: data.dateISO };
    		HTTP.put( invAPI+"/deleteGridReplacement/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+data.grid.id, xdata)
            .then( response => 
            { 
                data.grid = response.data;
               
                that.update();
            
            }).catch(e => {
                    showError( that.$toast, "remove Grid", e);
              

            });
    	});
    	
    },
    getName( grid, dateStr)
    {
    	let date = new Date( dateStr);
    	
    	let repl = grid.detail.gridReplacements && grid.detail.gridReplacements.replacements ? grid.detail.gridReplacements.replacements.find(p=>p.date==dateStr): null;
    	if ( repl )
    	{
    		return repl.name;
    	}
    	//let dateStr = new Date(date).toISOString().split('T')[0];
    	return grid.detail.name;
    },
    getImgData(val) { return 'data:image/png;base64,' + val; },
    nodeRClicked(event, dateStr, day)
    {
    	//alert( JSON.stringify(event));
        event.preventDefault();
        
        let date = new Date( dateStr );
        
        //this.$refs.menu.open( {clientX: 1, clientY: 1}, {detail: {id: 0, name: "Test"}})
        if (  (this.activeGrid.detail) )
        {
	          let month = date.toLocaleString('default', { month: 'long' });
        	  let d = date.getDate()+"-"+month+"-"+date.getFullYear();
        	  let dISO = date.toISOString().split('T')[0];
        	  
        	  let repl = this.activeGrid.detail.gridReplacements && this.activeGrid.detail.gridReplacements.replacements ? this.activeGrid.detail.gridReplacements.replacements.find(p=>p.date==dISO): null;
        	 
        	  //alert( this.activeGrid.detail.id + "/"+ d+"/"+dISO+"/"+JSON.stringify(this.activeGrid.replacements))
              this.$refs.menu.open(event, { grid: this.activeGrid, replace: repl, date: d, dateISO: dISO, dayOfWeek: day} )
        }
        else if (  this.activeDay )
        {
              let y0 = event.clientY;
              //alert( this.activeDay);
              let timeStartsAt = $("#timeLine")
			  let offset = timeStartsAt.offset();
			  let firstTime = offset.top;
    	
    		  let y = this.dayStartAt + Math.round((y0-firstTime)/1.33333 * this.unit * 60 /  this.zoom);
	          y = y - y % 300;
	          let time = { time: this.printTime(y), grid: {}, timeSec: y, day: this.activeDay }
	          this.$refs.menu.open(event, time )
        }
       
    },
    newGrid( event, ctx )
    {
    	this.$refs.menu.close();
    	this.openGRDPP = true;
    	this.$refs.completer.open( this.header, this.zones, ctx.timeSec, ctx.time, ctx.day);
    },
    removeGrid( event, ctx )
    {
    	this.$refs.menu.close();
    	let that = this;
    	this.$refs.confirm.confirm( {text: "Remove " +ctx.detail.name, title: "Delete", button1: "Cancel", button2: "Delete"}).then(x => {
	    	HTTP.put( invAPI+"/removeGridElement/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+ctx.header.id+"/"+ctx.detail.id)
	            .then( response => 
	            { 
	                
	                that.update();
	            
	            }).catch(e => {
	                    showError( that.$toast, "remove Grid", e);
	              
	
	            });
    	});
    },
    show( data)
    {
    	
    },
    showAdvertising( event, ctx )
     {
    	this.$refs.menu.close();
    	let that = this;
    	//alert(invAPI+"/showGridAdvertising/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+ctx.header.id+"/"+ctx.detail.id)
    	HTTP.post( invAPI+"/showGridAdvertising/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+ctx.header.id+"/"+ctx.detail.id)
            .then( response => 
            { 
                let data = response.data;
                
                this.showAsText = "<b>ProductFamily:</b><br/><table>";
		        for ( var pi in data.prodFamily )  
		        { 
		        	let productFamily = data.prodFamily[pi];
		        	this.showAsText += "<tr><td>" + pi+ "</td><td>" +productFamily+ "</td></tr>";
		        }
		        this.showAsText += "</table>";
		        this.showAsText += "<b>Campaigns</b>:<br/><table>";
		        for ( var ci in data.campaign )  
		        { 
		        	let campaign = data.campaign[ci];
		        	this.showAsText += "<tr><td>" + ci+ "</td><td>" +campaign+ "</td></tr>";
		        }
		        this.showAsText += "</table>";
		        //this.showAsText = JSON.stringify(data.issueSpots);
		        this.infoTime = new Date().getTime();
		        this.asText = ctx.detail.name;
            
            }).catch(e => {
                    showError( that.$toast, "showAdvertising", e);
              

            });
    },
    getPGStyle(rc)
    {
    	if ( this.showRC )
    	{
    		return "background-color: "+rc.colour+"; color: "+autoFG(rc.colour)+";font-size: 7pt; width: 40%; text-align: center; margin-left: 6ex;";
    	}
    	else
    	{
    		return "background-color: "+rc.colour+"; font-size: 0pt; width: 40%; text-align: center;"
    	}
    },
    unitChanged(value) {
        //alert(value);
    	this.unit = value;
    	this.setValues(this.dayStartAt);
    },
    onDropGrid( evt, day, addDay )
    {
    	// var start = (grid.detail.starttime-this.dayStartAt)/60* this.zoom / this.unit
    	
    	let grid = JSON.parse( evt.dataTransfer.getData('grid'));
    	console.log(JSON.stringify(grid))
    	let y0 = evt.clientY;
    	let by = evt.dataTransfer.getData('mY');
    	let y = Math.round((y0-by)/1.33333 * this.unit * 60 /  this.zoom);
		//alert( y1+"/"+ evt.dataTransfer.getData('startBY') +"/"+evt.dataTransfer.getData('startY') +"/"+y0);
    	this.setStartTime( grid, y, day, addDay);
    },
    onDropGridCopy( event, day )
    {
        
    	let y0 = event.clientY;
      	//alert( this.activeDay);
      	let timeStartsAt = $("#timeLine")
	  	let offset = timeStartsAt.offset();
	  	let firstTime = offset.top;
		this.activeDay = day;
	  	let y = this.dayStartAt + Math.round((y0-firstTime)/1.33333 * this.unit * 60 /  this.zoom);
      	y = y - y % 300;
      	let grid = JSON.parse( event.dataTransfer.getData('grid'));
      	let time = { grid: grid, time: this.printTime(y), timeSec: y, day: this.activeDay }
	    //alert( JSON.stringify(time));
	    let that = this;
	    let newElement = 
	        {grid: JSON.stringify(grid.detail), 
	         time: y, 
	         day: this.activeDay
	        };
	    alert( JSON.stringify( newElement))
	    HTTP.put( invAPI+"/newGridElement/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+grid.detail.headerId, newElement)
            .then( response => 
            { 
                that.update();            
            }).catch(e => {
                    showError( that.$toast, "new Grid", e);
              

            });
    },
    startDragGrid( evt, grid )
    {
    	evt.dataTransfer.setData('grid', JSON.stringify(grid));
    	//alert( evt.source.getBoundingClientRect().top+"/"+evt.clientY);
    	evt.dataTransfer.setData('startY', evt.target.getBoundingClientRect().y);
    	evt.dataTransfer.setData('mY', evt.clientY);
    	console.log(JSON.stringify(evt.target.getBoundingClientRect()))
    	
    	evt.dataTransfer.effectAllowed = "move";
    },
    setStartTime( grid, time, day, addDay)
     {
     	let that = this;
     	
     	//alert(JSON.stringify(ratecard))
        if ( grid.detail.starttime != time )
        {
          
            let timezoneId = this.selectedTimezone !== null && this.selectedTimezone.id?this.selectedTimezone.id:0;
            //alert(grid.detail.starttime +"<--"+ time); 
	        grid.detail.starttime += time - time % 300;
	        let replaceId = ( that.replacement && that.replacement.id ) ? that.replacement.id : 0;
	     	let loader = new Promise(function (resolve, reject) {
	     		//alert( invAPI+"/updateDetailVersion/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+grid.detail.id+"/"+grid.header.id+"/"+that.tz.id+"/"+timezoneId+"/"+day+"/"+addDay);
		        HTTP.put( invAPI+"/updateDetailVersion/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+grid.detail.id+"/"+replaceId+"/"+grid.header.id+"/"+that.tz.id+"/"+timezoneId+"/"+day+"/"+addDay+"/-1/false", grid.detail)
		            .then( response => 
		            { 
		                that.update();
		                
		            }).catch(e => {
		                    that.$toast.error("new version: " + e.response.data, 'Error', { position: "topRight" });
	     
		                    reject ();
		            });
		      });
		}
     },
    nodeToOpen( evt, id )
    {
        let that = this;
        this.$refs.menu.close();
        console.log(fwAPI+"/entityByIdWithMetadata/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/GridDetail/"+id)
        let loader = new Promise(function (resolve, reject) {
	        HTTP.get( fwAPI+"/entityByIdWithMetadata/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/GridDetail/"+id)
	            .then( response => 
	            { 
	                //alert(JSON.stringify(response.data));
	                that.selectedRecord = response.data.data;
	                that.metadata = response.data.metadata;
	                resolve (response.data);
	                
	            }).catch(e => {
	                    this.error("loading data for client", e);
	                    reject ();
	            });
	      });
	   loader.then( inventory => {
        that.mapping = [ {row: 0, dataId: that.selectedRecord.id, rId: 0, visible: true}];
    	that.allEntities = [ that.selectedRecord];
		that.dataId = that.selectedRecord.id;
    	that.showEditor = true;
    	});
    	//this.$emit(node.module.toLowerCase(), node.entitydata);
    },
     openEntity( evt, entity, id )
     {
        let that = this;
        console.log(fwAPI+"/entityByIdWithMetadata/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+entity+"/"+id)
        let loader = new Promise(function (resolve, reject) {
	        HTTP.get( fwAPI+"/entityByIdWithMetadata/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+entity+"/"+id)
	            .then( response => 
	            { 
	                //alert(JSON.stringify(response.data));
	                that.selectedRecord = response.data.data;
	                that.metadata = response.data.metadata;
	                resolve (response.data);
	                
	            }).catch(e => {
	                    this.error("loading data for client", e);
	                    reject ();
	            });
	      });
	      
	   loader.then( inventory => {
        that.mapping = [ {row: 0, dataId: that.selectedRecord.id, rId: 0, visible: true}];
    	that.allEntities = [ that.selectedRecord];
		that.dataId = that.selectedRecord.id;
    	that.showEditor = true;
    	});
    	//this.$emit(node.module.toLowerCase(), node.entitydata);
     },
    getLocalTZ() {
     if ( this.tz2 )
     {
     	return this.tz2;
     }
     return {};
    },
    gridTitleStyle(gridAds, date) 
    {
    	if ( date )
        {
    		let dISO = date;
      	    let repl = null; // gridAds.replacements.find(p=>p.gridDetailId==gridAds.detail.id && p.date==dISO);
        	if ( repl )
        	{
        		return "font-size: 8pt; text-align: center; display: block; color: #00a; border: 1pt solid grey;";
        	}
        }
     	if ( gridAds.originalHeader.id !== gridAds.header.id )
     	{
     	    return "font-size: 8pt; text-align: center; display: block; color: #888;";
     	}
     	else
     	{
     		return "font-size: 8pt; text-align: center; display: block;";
     	}
     },
    showGrid( evt, grid, date, dayOfWeek ) {
        this.$refs.menu.close();
  	    let repl = this.activeGrid.gridReplacements && this.activeGrid.gridReplacements.replacements ? this.activeGrid.gridReplacements.replacements.find(p=>p.date==date): null;
  	    //alert( JSON.stringify( repl))
    	if ( repl )
    	{
    		this.useActiveReplacement = {...this.activeGrid}
    		this.useActiveReplacement.name = repl.name;
    	}
    	else
    	{
    		this.useActiveReplacement = {};
    		
    	}
  	    
  	    this.dayOfWeekBit = 2**dayOfWeek;
    	this.selectedGrid = grid;
    	this.showBreakView = true;
    },
    closeGrid() {
    	this.showBreakView = false;
    },
    closeEditor()
    {
         this.showEditor = false;
    },
    gridHeight() { 
       var height = this.zoom; 
       var fontSize = height/2+2;
    	if ( fontSize < 5 )
    	{
    		fontSize = 5;
    	}
    	if ( fontSize > 10 )
    	{
    		fontSize = 10;
    	}
       return "height: "+this.zoom+"pt; font-size: "+fontSize+"pt;";},
    mainTime(time) { 
        let ss = time % 60;
        let mm = ((time - ss) / 60 ) % 60;
        let hh = ((time - ss - mm * 60) / 3600 ) % 60;
        var height = this.zoom; 
        var fontSize = height/2+2;
    	if ( fontSize < 5 )
    	{
    		fontSize = 5;
    	}
    	if ( fontSize > 10 )
    	{
    		fontSize = 10;
    	}
        if ( hh >= 24 )
        {
        	return "height: "+this.zoom+"pt; font-size: "+fontSize+"pt; background-color: #e0e0ff;";
        }
      	return "height: "+this.zoom+"pt; font-size: "+fontSize+"pt;";
      },
    adSpaces( grid, date, dayOfWeek)
    {
    	if ( !this.withAds  )
    	{
    		//return [];
    	}
    	let wd = dayOfWeek;
    	if ( grid.adspacePerWeekday && grid.adspacePerWeekday[wd])
    	{
    		return grid.adspacePerWeekday[wd];
    		
    	}
    	return grid.adspace;
    },
    showBreaks() {
        //JSON.stringify( this.station);
    	this.setValues( this.dayStartAt);

    	//alert( this.document.querySelector('.adMain').length);
    	//this.document.querySelector('.adMain').classList.toggle('change');
    },
    setValues(minStarttime)
      {
          //if ( !this.dayStartAt ) // || this.dayStartAt != minStarttime )
          {
          this.dayStartAt = minStarttime;
          this.weekdayLabel = [];
          let wdArr = JSON.parse(sessionStorage.weekdays);
          for ( var i=1; i < 8; i++ )
          {
             let x = wdArr[i];
             if ( x.length > 2 )
             {
                 x = x.substring(0,2);
             }
             this.weekdayLabel.push( x);    
          }
          this.grid=[];
         
          for ( var m=0; m <= 48 * 60;)
          {
              let unit = this.unit;
              
          	  if ( m*60 >= minStarttime && m*60 <= minStarttime + 24*3600 )
	            {
	          		this.grid.push(m*60);
	          	}
	          	m=m+unit;
          }
          }
      },
    update() {
       this.getInventory();
    },
    
    gridStyle(grid) {
    	var style = "top: ";
    	var height = grid.detail.durationISOInSeconds/60 * this.zoom / this.unit; 
    	var start = (grid.detail.starttime-this.dayStartAt)/60* this.zoom / this.unit
    	style +=  start+"pt; height: "+height+"pt;";
    	if ( this.colourCAT == 'category' )
    	{
    		style +=  "background-color: "+grid.category.colour+"; color: "+autoFG(grid.category.colour)+" !important;";
    	}
    	if ( this.colourCAT == 'zone' && grid.zone )
    	{
    		style +=  "background-color: "+grid.zone.colour+"; color: "+autoFG(grid.zone.colour)+" !important;";
    	}
    	if ( this.colourCAT == 'daypart' && grid.daypart )
    	{
    		style +=  "background-color: "+grid.daypart.colour+"; color: "+autoFG(grid.daypart.colour)+" !important;";
    	}
    	else if ( this.colourCAT == 'ratecard' && grid.rateCard)
    	{
			if (!grid.rateCard.colour)
			{
				style +=  "background-color: #fff; color: "+autoFG("#fff")+" !important;";
			}
			else
			{
    			style +=  "background-color: "+grid.rateCard.colour+"; color: "+autoFG(grid.rateCard.colour)+" !important;";
    		}
    	}
    	return style;
    },
    adStyle(grd, ad) {
            	
    	var height = 6 * this.zoom / this.unit; 
    	if ( !this.withAds  )
    	{
    		return "background-color: transparent; color: transparent;";
    	}
    	if ( height > 16 )
    	{
    		height = 14;
    		
    	}
    	var fontSize = 1+height/2;
    	if ( fontSize < 5 )
    	{
    		fontSize = 5;
    	}
    	var start = ad.estStarttime;
    	
    	start = ((start - grd.detail.starttime))/60* this.zoom / this.unit;
    	if ( ad.section == 0 )
        {
        	start = -height
        } 

    	var style =  "top: " + start+"pt; height: "+height+"pt; font-size: "+fontSize+"pt;";
    	style += "border: 1pt solid #aaa;";
    	//console.log( grd.detail.name +"-"+ad.no+"/"+ad.estStarttime+"/"+grd.detail.starttime+"/"+this.dayStartAt+" -> "+style);
    	return style;
    },
    timezoneStyle() {
        if ( this.timezoneId > 0 )
        {
    		return "float: left; width: 6%  !important; background-color: #eee; color: #444;";
    	}
    	return "float: left; width: 0pt !important; background-color: #eee; color: transparent;";
    },
    weekdayStyle() {
    	if ( this.timezoneId > 0 )
        {
    		return "float: left; width: 12.5%;";
    	}
    	return "float: left; width: 13.4%; ";
    },
    adLabel(ad) {
        var text = "";
        if ( ad.section == 0 )
        {
        	text = "OPENER";
        }
        else if ( ad.section == 2 )
        {
        	text =  "CLOSER";
        }
        else
        {
        	text =  "C-"+ad.no;
        }
        text += " [ ";
    	for ( var type in ad.breakTypes )
    	{
    		text += ad.breakTypes[type].shortname+ " ";
    	}
    	text += "]";
        return text;
    },
    printDate(date)
    {
    	let d = new Date(date);
    	return d.getDate()+"/"+(d.getMonth()+1);
    },
    weekDayIndexes() {
            var intArr = [];
            //console.log(sessionStorage.weekStart);
            for ( var i=0; i < this.weekdayLabel.length; i++ )
            {
            	let index = (i + parseInt(sessionStorage.weekStart)) % 7;
                intArr.push(index);
                this.dateArr[index] = this.dates[ i];
                //console.log( i +"/" + index+ " --> "+JSON.stringify(this.dateArr[index]));
                //console.log(i+"/" + sessionStorage.weekStart+ "--> " +((i + sessionStorage.weekStart) % 7));
            }
            //console.log("weekDayIndexes>" +JSON.stringify(intArr));
            return intArr;
        },
    fmtTimePart( aTime) {
      	if ( aTime < 10 ) 
      	{
      		return "0"+aTime;
      	}
      	return ""+aTime;
      },
   printTime(time)
      {
        //console.log( "scanTime("+val+") " + this.time )
        //alert("scanTime("+JSON.stringify(val)+") " + this.time)
        if ( isNaN( time ) || ! time  )
        {
        	time = 0;
        }
      	let ss = time % 60;
        let mm = ((time - ss) / 60 ) % 60;
        let hh = ((time - ss - mm * 60) / 3600 ) % 60;
        let hhVal = this.fmtTimePart(hh);
        let mmVal = this.fmtTimePart(mm);
        //let ssVal = this.fmtTimePart(ss);
        return hhVal +":"+mmVal;
       },
    printTime2(time)
      {
        //console.log( "scanTime("+val+") " + this.time )
        //alert("scanTime("+JSON.stringify(val)+") " + this.time)
        if ( isNaN( time ) || ! time  )
        {
        	time = 0;
        }
        let minusDay=false;
        let plusDay=false;
        if ( time < 0 )
        {
        	time += 24*3600;
        	minusDay = true;
        }
      	let ss = time % 60;
        let mm = ((time - ss) / 60 ) % 60;
        let hh = ((time - ss - mm * 60) / 3600 ) % 60;
        
        
        if ( hh >= 24 )
        {
        	hh -= 24;
        	plusDay = true;
        }
        
        let hhVal = this.fmtTimePart(hh);
        let mmVal = this.fmtTimePart(mm);
        //let ssVal = this.fmtTimePart(ss);
        return hhVal +":"+mmVal +((plusDay)?" +1":"")+((minusDay)?" -1":"");
       },
    dateChanged(newDate) {

    	this.activeDate = newDate;
    	this.getInventory();
    },
    getInventory() { 
        this.pbAction = "getInventoryInternal";
        this.pbTitle = "reading inventory";
 	    this.showProgressBar = true;
    },
    getInventoryInternal( wsId, api) { 
       let that = this;
      
       this.showProgressBar = true;
       //console.log( "getInventory()");
       let dateStr = new Date(this.activeDate).toISOString().split('T')[0];
	   
       //console.log( dateStr);
       let minStarttimeInit = 99999999;
       let minStarttime = minStarttimeInit;
      
       if ( this.selectedTimezone && this.selectedTimezone.id )
       {
       	 	this.timezoneId = this.selectedTimezone.id;
       }
       if ( this.selectedStation && this.selectedStation.id )
       {
       	 	this.stationId = this.selectedStation.id;
       }
       if ( !this.selectedTimezone  )
       {
       		this.timezoneId = 0;
       }
       
       let stationLoad = new Promise(function (resolve, reject) {
           HTTP.get( gridAPI+"/getStations/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+dateStr)
    		 .then( response => 
             {
                let allStations = response.data.channels; //.map(p=>{return  {id: p.id, label: p.name, visible: true}});
                that.stations = [];
               
                for ( let channel in allStations )
                {
                	if ( allStations[channel].visible )
                	{
                		that.stations.push( allStations[channel]);
                		if ( allStations[channel].id == that.stationId || ! that.stationId)
                    	{
                    		that.selectedStation = allStations[channel];
                    		
                    		that.stationId = that.stations[channel].id;
                    	}
                	}
                	
                }    
                
                resolve (that.stationId);                    
             }).catch(e => {
					
                    that.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
					
                    reject ();
                });
				
		});		

	   stationLoad.then( selectedStationId => {
		   
	        let timezoneLoad = new Promise(function (resolve, reject) {
		   	    console.log(invAPI+"/getMediaAndTimezone/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+selectedStationId+"/"+that.timezoneId);
		        HTTP.get( invAPI+"/getMediaAndTimezone/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+selectedStationId+"/"+that.timezoneId)
	        		 .then( response => 
	                 {  
	                    that.station = response.data.channel;
	                    that.tz = response.data.timezone;
	                    that.tz2 = response.data.secondTimezone;
	                    that.timezones = [];//response.data.allTimezones;
	                    for ( let t in response.data.allTimezones)
	                    {
	                    	that.timezones.push( {id: response.data.allTimezones[t].id, label: response.data.allTimezones[t].name});
	                    	if ( response.data.allTimezones[t].id == that.timezoneId )
	                    	{
	                    		that.selectedTimezone = {id: response.data.allTimezones[t].id, label: response.data.allTimezones[t].name};
	                    	}
	                    }

	                    resolve (response.data.timezone);
	                 }).catch(e => {
						    that.showProgressBar = false;
	                        that.$toast.error("loading data for media/timezones: " + e.response, 'Error', { position: "topRight" });
							reject ();
	                    });
	
	           });
			
		   timezoneLoad.then( timeZone => {
		   	    let inventoryLoad = new Promise(function (resolve, reject) {
					
		   	    //console.log(invAPI+"/getAllInventory/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.station.id+"/"+timeZone.id+"/"+that.timezoneId+"/"+dateStr+"/"+sessionStorage.weekStart);
		        api.get( invAPI+"/getAllInventory/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.station.id+"/"+timeZone.id+"/"+that.timezoneId+"/"+dateStr+"/"+sessionStorage.weekStart+"/"+wsId)
	        		 .then( response => 
	                 {  
	                    that.gridElements = response.data.weekArray;
						
	                    that.dates = response.data.dates;
	                    that.zones = response.data.zones;
	                    
	                   
	                    that.diff = response.data.weekArray.diff;
	                    that.currency = response.data.currency;
	                    that.header = response.data.header;
	                    that.allRateCards = response.data.ratecards;

	                    that.diff = response.data.diff;
	                    
	                    that.rateCards=[];
	                    var rcTMPArr = [];
	                    var y = 0;
						for ( let w=0; w < 7; w++)
						{ 
							for ( var i in that.gridElements[w])
							{
								let starttime = that.gridElements[w][i].detail.starttime;
								try
								{
									rcTMPArr[that.gridElements[w][i].rateCard.pricegroup] = that.gridElements[w][i].rateCard;
									if ( y == 0)
									{
										that.diff = that.gridElements[w][i].diff;
									}
								} catch (e) {
									//
									}
								minStarttime = Math.min( minStarttime, starttime);
								
								y++;
							}
						}
						try
						{
							var idx = 0;
							for ( var i2 = 0; i2 < rcTMPArr.length; i2++ )
							{
								if ( rcTMPArr[i2] )
								{
									that.rateCards[ idx++]=rcTMPArr[i2];
								}
							} 
						} catch (e) {
						//
						}
						that.weekDayIndexes();
						if ( minStarttimeInit == minStarttime )
						{
							minStarttime = that.station.dayStart;
						}
	                    resolve (minStarttime);
	                    //that.showProgressBar = false;    
	                 }).catch(e => {
	                	    that.showProgressBar = false; 
	                        that.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
     
	                        reject ();
	                    });
	
	           });
	           inventoryLoad.then(minStarttime => {
	        	    
	                that.setValues(minStarttime);
				   	// force recalc () (open&close)--> workaround as the first time the context menu opens at (0,0)
				   	try {
				   	that.$refs.menu.open( {clientX: 1, clientY: 1}, {detail: {id: 0, name: "Test"}}); } catch (e) {
				   	//
				   	}
				   	that.$refs.menu.close();
				   	that.showProgressBar = false; 
				   	//alert(minStarttime);
			   });
		   	}
	   	  )
	   });
    }
  },
  created() {
  	this.timezoneId = sessionStorage.timezoneId;
  	this.activeDate = new Date();
  	if ( this.$route.query.stationId && this.$route.query.day )
    {
    	this.activeDate = new Date(this.$route.query.day);
    	this.selectedStation=null;
    	this.stationId=parseInt(this.$route.query.stationId);
    }
  	this.getInventory();
  	initAppMode();
  },
  watch: {
  	zoom: function() { } 
  }
  
}
</script>
<style>
.time { 
    
    font-size: 7pt; 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; 
    border-left: 1px solid #ccc; 
    border-bottom: 1px solid #ccc; 
    background-color: #eef; 
    opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s;
 }
 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  font-weight: bold; 
  width: 100% !important; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #eef; 
  color: #000;
  }
.headerDay { 
  font-size: 12pt; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc; 
  background-color: #eef; 
  color: #000;
 }
.adMain { 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  z-Index: 17; 
  background-color: #fff; 
  margin-left: 4pt; 
  margin-right: 4pt; 
  color: #666; 
  width: 95%; 

    opacity:1;
    transition: all .5s;
   -webkit-transition: all .5s;
}

.gridMain { position: absolute; z-Index: 0; text-align: center; width: 99% ;border: 1pt solid #aaa; opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s; }
   
.SVcontrolLabel {
	font-weight: bold;
	font-size: 10pt;
	padding-top: 8pt;
}

.mini {
    margin-top: 4pt;
	height: 16pt;
	font-size: 9pt;
	border-radius: 7px;
	box-shadow: 4px, 4px 2px 0 #ccc;
}

.selected {
    
	font-weight: bold;
	background-color: #ccc !important;
	color: #000 !important;
}
</style>
