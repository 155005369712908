<template>
<div>   
	  <XModal v-if="time" :name="name"  :resizable="true" :width="width+'%'" :height="height+'%'" :draggable="true" >
	  <div :style="getAppStyle()+'height: 100%;border: 2pt inset grey;'">
	  <div class='appHead'>
             <div style='display: inline-flex; float: left; font-weight: bold; padding-top: 2pt; margin-right: 15pt;'>
             
             </div>
            
              <button id='closePopup2' type="button" class="iButton" @click='close' data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			  </span>
              </button>
            </div> <!-- appHead -->
       <div class="modal-header">
          <slot name="header">
            <div class='appHeadLine'>
            
            	<div class='appLeft'>
            	Create Grid-Element for {{gridHeader.name}}
                </div>	

            </div> <!-- appHeadLine -->
            
           </slot>
        </div> <!-- modal-header -->  	  
        <div class="modal-body" id='main-body'>
	  
	      <div :style='getTableStyle()' >
          <slot name="body">
           
            <div class='mainContent' >
               <table  width='100%' style='border-collapse:separate; border-spacing:2pt;'>
               <tr>
				  <td class='separationText' colspan=5>Content</td>
			   </tr>
               <tr>
				  <td class='label' :style='color()'>Choose Content</td><td colspan=4>
				  <GSelectSimple :nulls="true" :showOpener="true" :options="contentOptions" entity='Content'  v-model="contentId"/>
				  </td>
               </tr>
               <tr>
				  <td>
				  	<span v-show='!contentId' class='label' :style='color()'>Create Content</span>
				  	<span v-show='contentId' class='label' :style='color()'>Create Content</span>
				  </td>
				  <td colspan=4 >
				  <div :class='getContentClass(!contentId)'>
				  <InputText :border="false" v-model="content"/>
				  </div>
				  <span :style="'vertical-align: bottom;font-size: 8pt;'+color()" v-show='contentId'>to enter a new content, clear the list above</span>
				  </td>
               </tr>
               <tr>
				  <td class='label' :style='color()'>Category</td><td colspan=1>
				   <GSelectSimple :disabled='contentId' :showOpener="true" :options="categories" entity='ContentCategory'  v-model="categoryId"/>
				  </td>
				  <td></td>
	              <td class='label' :style='color()'>Genre</td><td>
	              <GSelectSimple :disabled='contentId' :showOpener="true" :options="genres" entity='Genre'  v-model="genreId"/>
	              </td>

               </tr>
               <tr>
				  <td class='label' :style='color()'>Ratings</td><td colspan=1>
				   <GSelectSimple :disabled='contentId' :showOpener="true" :options="ratings" entity='ParentalRating'  v-model="ratingId"/>
				  </td>
				  <td></td>
	              <td class='label' :style='color()'>TargetGroup</td><td>
	              <GSelectSimple :showOpener="true" :options="targetGroups" entity='TargetGroups'  v-model="targetGroupId"/>
	              </td>

               </tr>
               <tr>
				  <td class='separationText' colspan=5>Timings</td>
			   </tr>
               <tr>
	              <td class='label' :style="color()+'width: 9%;'">Starttime</td><td style='width: 39%;'><InputTime2 :border="false" v-model="time"/></td>
	              <td style='width: 2%;'></td>
	              <td class='label' :style="color()+'width: 9%;'">Duration</td><td style='width: 39%;'><InputTime2 :border="false" v-model="duration"/></td>

               </tr>
               
               <tr>
	              <td class='label' :style='color()'>Date from</td><td><InputDatePick :auto="false" :border="false" v-model="dateFrom"/></td>
	              <td></td>
	              <td class='label' :style='color()'>To</td><td style='width: 30%;'><InputDatePick :auto="false" :border="false" v-model="dateTo"/></td>

               </tr>
               <tr>
				  <td class='label' :style='color()'>Weekdays</td><td colspan=4>
				  <InputWeekdays :border="false" v-model="weekdays"/>
				  </td>
               </tr>
               <tr>
				  <td class='separationText' colspan=5>Advertising</td>
			   </tr>
               <tr>
				  <td class='label' :style='color()'>Breaktypes</td><td colspan=4>
				  <GSelectMSimple :showOpener="true" :options="breakTypes" entity='BreakType'  v-model="breakTypeIds"/>
				  </td>
               </tr>
               
                <tr>
	              <td class='label' :style='color()'>Ad-Time</td><td><InputTime2 :border="false" v-model="adDuration"/></td>
	              <td></td>
	              <td class='label' :style='color()'>Zone</td>
	              <td>
	              
			  		<GSelectSimple :showOpener="true" :options="zones" entity='GridZone'  v-model="zoneId"/>
	              </td>

               </tr>
               <tr>
	              <td class='label' :style='color()'>#Interruptions</td><td><InputInt :border="false" v-model="noOfInt"/></td>
	              <td></td>
	              <td class='label' :style='color()'>Pricegroup</td><td><InputInt :border="false" v-model="pricegroup"/></td>

               </tr>
 
               </table>
               <div style='position: absolute; float: bottom; width: 100%; bottom: 0;  padding-right: 15pt; padding-bottom: 8pt;'>
                 <Button :disabled="!canSave()" style='float:right;' class='button btn-save' @click="save">Save</button>
                 <Button :disabled="!canSave()" style='float:right;' class='button' @click="close">Close</button>
               </div>
			</div>

	      </slot>
	      </div>
	     
	      </div>
		</div>	
	  </XModal>
	  
	<GFWEOpenEditor ref='editor'></GFWEOpenEditor>

	<GConfirm ref='confirm'/>
	
</div>
</template>


<script>
import {HTTP, opsAPI, fwAPI, invAPI, setReload, showError} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode, hexToRgb, isDark } from '@/AppStyle.js';
import { tr } from '@/translate.js';
import InputDatePick from '@/components/inputElements/InputDatePick3';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputText from '@/components/inputElements/InputText';
import InputInt from '@/components/inputElements/InputInt';
import InputTime2 from '@/components/inputElements/InputTime2';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import InputTime from '@/components/inputElements/InputTime';
import GSelectSimple from '@/components/GSelectSimple';
import GSelectMSimple from '@/components/GSelectMSimple';
import Checked from '@/components/Ops/Checked';
import GConfirm from '@/components/GConfirm';
import ClickOutside from 'vue-click-outside'
import PlacementWizard from '@/components/Ops/PlacementWizard';
import CampaignWizard from '@/components/Ops/CampaignWizard';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import JQuery from "jquery";
let $ = JQuery;

export default {
  name: 'SCHEDULE_GRID_CREATE',
  components : {
   GConfirm, InputTime2, GSelectSimple, GSelectMSimple, InputWeekdays, InputInt, InputDatePick, InputText
  },
  props: {
    name: {
      type: String,
      default: 'CompleteWidget'
    },
    
    height: {
      type: Number,
      default: 75
    }
  },
  data () {
    return {
		width: 66,
		time: 0,
		duration: 3600,
		adDuration: 600,
		contentId: 1,
		categoryId: 1,
		genreId: 1,
		zoneId: 1,
		dateFrom: {},
		gridName: "",
		content: "",
		dateTo: {},
		weekdays: 127,
		gridHeader: {},
		contentOptions: [],
		contents: [],
		targetGroups: [],
		targetGroupId: 1,
		ratings: [],
		zones: [],
		ratingId: 5,
		breakTypes: [],
		categories: [],
		genres: [],
		breakTypeIds: [],
		pricegroup: 0,
		noOfInt: 1,
		day: {},
		ts: "",
        tr: tr,
		getAppStyle
    }
  },
  directives:{
    ClickOutside,
  },
  
  methods: {
     close() {  this.$modal.hide(this.name); this.$emit("close") },
     color() { return "color: "+(isDark(sessionStorage.bgColor)?"#ddd":"#111")+";"}, 
     getWidth() { return this.width+"%";},
     canSave()
     {
     	return true;
     },
     getContentClass(allow)
     {
     	if ( allow)
     	{
     		return "createContent";
     	}
     	return "invisible";
     },
     getComplStyle(id)
     {
     	if (id) {
     		return "color: #000;";
     	}
     	return "color: #bbb;"
     },
     isCompleted()
     {
     	return true;
     },
     check(val)
     {
     	//console.log( val);
     },
     openWizard( ) {
    	this.$refs.placementWizard.open(this.popupData);
     },
     openCampaignWizard( ) {
    	this.$refs.campaignWizard.open(this.popupData);
     },
     campaignChanged() {
     	//alert(this.campaignId +"/"+this.popupData.line.campaignId)
     },
	 open(gridHeader, zones, time, ts, day) {
	    this.time = time;
	  
	    this.zones = zones.map(z=>{ return { id: z.id, label: z.name, colour: z.colour, visible: true}})
	    this.ts = ts;
	    this.day = day; 
	    this.contentId = 1;
	    this.dateFrom = gridHeader.fromDate; 
	    this.dateTo = gridHeader.untilDate; 
	    this.weekdays = parseInt(Math.pow(2, day));
	    this.gridHeader = gridHeader;
	    this.$emit("open");
	    this.content = "";
	    let that = this;
	    //alert( invAPI+"/getData4NewGrid/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+gridHeader.id+"/"+time+"/"+day);
	    HTTP.post( invAPI+"/getData4NewGrid/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+gridHeader.id+"/"+time+"/"+day) 
            .then( response => 
            { 
            	 that.contents = response.data.content;
                 that.contentOptions = response.data.content.map( p => {return {id: p.id, label: p.name, visible: true};});
                 that.breakTypes = response.data.breakTypes.map( p => {return {id: p.id, label: p.shortname, visible: true};});
                 that.categories = response.data.categories.map( p => {return {id: p.id, label: p.name, visible: true};});
                 that.genres = response.data.genres.map( p => {return {id: p.id, label: p.name, visible: true};});
                 that.ratings = response.data.ratings.map( p => {return {id: p.id, label: p.name, visible: true};});
                 that.targetGroups = response.data.targetGroups.map( p => {return {id: p.id, label: p.name, visible: true};});
                 that.pricegroup = response.data.pg;
                 that.contentOptions.sort(function(a,b) {
				     	let v1 = a.label;
			            let v2 = b.label;
			            
			            if ( v1 > v2 )
			            {
			                
			                return 1;
			            }
			            if ( v1 < v2 )
			            {
			                return -1;
			            }
				     	return 0;
				     });
                 if ( response.data.breakTypeIds )
                 {
                 	that.breakTypeIds = response.data.breakTypeIds;
                 }
            }).catch(e => {
                    showError( that.$toast, "loading "+name, e);
             });   
            
	 	this.$nextTick(function () {
		   	try {
		   	   
		   		that.$modal.show(that.name);
		   		that.$set( that, "time", time );
		   		setTimeout( 500, function() { that.$forceUpdate()});
		   	} catch (e) {
		   	//
		   	}

   		});
	 	

	 },
     getTableStyle() {
        
     	//return "overflow-y: scroll; height: calc(60vH - 104px)";
     	return "overflow-y: auto; height: calc("+this.height+"vH - 104px)";
     },

     
     closeEditorDetail()
	 {
	        this.showEditorDetail = false;
	 },
	 closeIf()
	 {
	 	if ( !this.showEditorDetail)
	 	{
	 		this.close();
	 	}
	 },
	 save()
	 {

	    let that = this;
	    let newElement = 
	        {contentId: this.contentId, 
	         content: this.content,
	         categoryId: this.categoryId,
	         targetGroupId: this.targetGroupId,
	         genreId: this.genreId,
	         ratingId: this.ratingId,
	         time: this.time, 
	         duration: this.duration,
	         adtime: this.adDuration, 
	         dateFrom: this.dateFrom, 
	         dateTo: this.dateTo, 
	         weekdays: this.weekdays,
	         zoneId: this.zoneId,
	         breakTypeIds: this.breakTypeIds, 
	         pricegroup: this.pricegroup, 
	         noOfInt: this.noOfInt
	        };
	    HTTP.put( invAPI+"/newGridElement/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.gridHeader.id, newElement)
            .then( response => 
            { 
                
                that.$emit("update")
                that.$modal.hide(that.name); 
                that.$emit("close");
            
            }).catch(e => {
                    showError( that.$toast, "new Grid", e);
              

            });
	 	
	 },
	 completed()
	 {
	   
	 	this.$emit("completed", this.popupData.line, this.popupData.row, true)
	 }
  },
  watch: {
		contentId: function() { 
			let content = this.contents.find(p=>p.id===this.contentId);
			this.duration = content.durationISOInSeconds;
			this.adDuration = Math.round(this.duration * 12 / 100);
			this.adDuration = (60+this.adDuration) - this.adDuration%60;
			this.ratingId = content.parentalRatingId;
			this.targetGroupId = content.targetGroupId;
			this.categoryId = content.categoryId;
			this.genreId = content.genreId;
		},
		content: function() {
			
		}
  },
  created() {

  }
}
</script>
<style scoped >

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.hTable {
  table-layout: fixed;
  width: 100%;;
}


.tdSmall {
  overflow: hidden;
  width: 5%;
}
.tdLarge {
  width: 25%;
  overflow: hidden;
}
.tdXLarge {
  width: 40%;
  overflow: hidden;
}
.appLeft {
	display:  inline-table; 
	text-align: right;
	width: 100%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 50%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.history {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 400 !important;
}
.label {
	text-align: left; 
	height: 10pt !important;
	padding-top: 5pt; 
	padding-bottom: 1pt; 
	padding-left: 10pt;
	font-size: 9pt;
	font-weight: normal;
}
.separationText {
	text-align: left; 
	height: 10pt !important;
	padding-top: 5pt; 
	padding-bottom: 1pt; 
	font-size: 10pt;
	font-weight: bold;
	border-bottom: 1px solid #ccf;
}
.createContent {
    transition: all .5s;
   -webkit-transition: all .5s;
}
.invisible {
    visibility: hidden;
    opacity: 0;
   
    height: 0pt !important;
	-webkit-transform: scale(0.0);
        -ms-transform: scale(0.0);
        transform: scale(0.0);
    transition: all .5s;
   -webkit-transition: all .5s;
}

.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
.mainContent {
  position: relative;
  height: 100% !important;
  
  float: top;
} 
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
}
.btn-small {
	height: 14pt;
}
.btn-save {
	background-color: #080 !important;
    color: #fff !important;
}
.button {
    background-color: white;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(0.7em - 1px);
    padding-left: .7em;
    padding-right: .7em;
    padding-top: calc(0.7em - 1px);
    text-align: center;
    white-space: nowrap;
    font-size: 10pt;
}
.button:hover {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    border: 1pt outset grey;
    background-color: #e8f0e8;
}
.modal-wrapper {

  vertical-align: middle;
}
td { 
    padding-right: 8px;
}

.modal-container {

  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 5px 0;
  padding: 5pt;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
