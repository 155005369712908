<template>
<div>   
	  <XModal v-if="popupData && popupData.line" :name="name" :resizable="true" :width="width+'%'" :height="height+'%'" :draggable="true" >
      <div :style="getAppStyle()+'height: 100%;border: 2pt inset grey;'">
		  <div class='appHead'>
	             <div style='display: inline-flex; float: left; padding-top: 2pt; margin-right: 15pt;'>
	                <div :style="'padding: 3pt; color: '+getFGColor('#000','#008')">
	            	  Create {{tr('Campaign')}} for {{popupData.line.name}}
	                </div>
	             </div>
	            
	              <button id='closePopup2' type="button" class="iButton" @click='close' data-dismiss="modal" aria-label="Close">
	              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
					  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
					  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
					</svg>
				  </span>
	              </button>
	            </div> <!-- appHead -->
	       <div class="modal-header">
	          
	        </div> <!-- modal-header -->  	  
	        <div class="modal-body" id='main-body' :style="getAppStyle()">
		  
		      <div :style='getTableStyle()' >
	          <slot name="body">
	           
	            <div class='mainContent' :style="getAppStyle()">
	               <table fixed width='100%'>
	               <tr><td width="20%">Name</td><td><InputText v-model='campaign.name'/></td></tr>
	               <tr><td>From/To</td><td>
	               <div style='display: inline-flex; width: 45%'>
	               <InputDatePick2 @change='getNewName' :auto='false' v-model='campaign.fromDate'/>
	               </div><div style='display: inline-flex; width: 10%'/>
	               <div style='display: inline-flex; width: 45%'>
	               <InputDatePick2 @change='getNewName' :auto='false' v-model='campaign.untilDate'/>
	               </div>
	               </td></tr>
	               <tr><td>Reference</td><td><InputText v-model='campaign.referenceCode1'/></td></tr>
				   <tr><td>{{tr("Product")}}</td><td><GSelectSimple :options="products" v-model="campaign.productId" entity='Product'/></td></tr>
	               <tr><td>{{tr("Deal")}}</td><td><GSelectSimple :options="deals" v-model="campaign.dealId" entity='Deal'/></td></tr>
	               <tr><td>{{tr("CampaignType")}}</td><td><GSelectSimple @change='getNewName' :options="campaignTypes" v-model="campaign.typeId" entity='CampaignType'/></td></tr>
	               <tr><td>{{tr("BillingParam")}}</td><td><GSelectSimple @change='getNewName' :options="billingParams" v-model="campaign.billingParameterId" entity='BillingParameter'/></td></tr>
				   <tr><td>{{tr("TaxSettings")}}</td><td><GSelectSimple @change='getNewName' :options="taxSettings" v-model="campaign.taxId" entity='TaxSettings'/></td></tr>
	               <tr><td>Status</td><td><GSelectSimple :options="campaignStatus" v-model="campaign.statusId" entity='CampaignStatus'/></td></tr>
	               
	               <tr><td>Budget</td><td>
	               <div style='display: inline-flex; width: 100%'>
	               	<InputMoney :currencyObj="currency" v-model='campaign.budget'/>
	               </div>
	               
	               </td></tr>
	
	               
	              
	               </table>
	               
	               <div style='position: absolute; float: bottom; width: 100%; bottom: 0;  padding-right: 15pt; padding-bottom: 8pt;'>
	                 <Button :style="getAppStyle()+'float:right;'" class='button btn-save' @click="save">Create {{tr('Campaign')}}</button>
	                 
	                 <Button :style="getAppStyle()+'float:right;'" class='button' @click="close">Close</button>
	               </div>
				</div>
	
		      </slot>
		      </div>
		     
		      </div>
		 </div>	
	  </XModal>
	  
	<GFWEOpenEditor ref='editor'></GFWEOpenEditor>
	<GConfirm ref='confirm'/>
	
</div>
</template>


<script>
import {HTTP, opsAPI, fwAPI, invAPI, setReload, showError} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode, getFGColor } from '@/AppStyle.js';
import { tr } from '@/translate.js';
import InputDateTime from '@/components/inputElements/InputDateTime';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputText from '@/components/inputElements/InputText';
import InputInt from '@/components/inputElements/InputInt';
import InputDatePick2 from '@/components/inputElements/InputDatePick3';

import InputMoney from '@/components/inputElements/InputMoney';
import GSelectSimple from '@/components/GSelectSimple';
import GSelectMSimple from '@/components/GSelectMSimple';
import Checked from '@/components/Ops/Checked';
import GConfirm from '@/components/GConfirm';
import ClickOutside from 'vue-click-outside'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import JQuery from "jquery";
let $ = JQuery;

export default {
  name: 'GOPS_CAMPAIGN_WIZARD',
  components : {
      GConfirm, InputText, InputMoney, GSelectSimple, InputDatePick2
  },
  props: {
    name: {
      type: String,
      default: 'CAMPAIGN_WIZARD'
    },
    height: {
      type: Number,
      default: 62
    }
  },
  data () {
    return {
		width: 54,
		currency: {},
		deals: [],
		products: [],
		campaignStatus: [],
		campaignTypes: [],
		billingParams: [],
		popupData: {},
		campaign: {},
		tr: tr,
		getAppStyle,
		getFGColor
    }
  },
  directives:{
    ClickOutside,
  },
  
  methods: {
     close() {  this.$modal.hide(this.name) },
     getWidth() { return this.width+"%";},

     save()
     {
     	this.createCampaign();
     },
     createCampaign()
	 {
	    let that = this;
	    //this.$refs.confirm.confirm( {text: 'Create '+that.title('placement')+ ' ' + that.placement.reference, title: "Create placement", button1: "Cancel", button2: "Create"}).then(x => {
	    that.$iosConfirm( 'Create '+tr('Campaign')+ ' ' + that.campaign.referenceCode1).then(x => { 
		 	HTTP.put( opsAPI+"/createCampaign/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+that.popupData.line.salesUnitId+"/"+sessionStorage.userId+"/"+that.popupData.line.id, {salesRequest: that.popupData.line, campaign: that.campaign})	
	    		 .then( response => 
	             {
	             	//alert( JSON.stringify(response.data));
	             	that.$set( that.popupData, 'line', response.data.salesRequest);
	             	
         			that.campaign = response.data.campaign;
         			that.$emit('campaign', that.campaign);
					that.close();
					
	             }).catch(e => {
	                    showError( that.$toast, "loading "+name, e);
	             });  

         });
	 },
	 getNewName()
	 {
	    let that = this;
	    HTTP.post( opsAPI+"/getCampaignName/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+that.popupData.line.salesUnitId+"/"+sessionStorage.userId+"/"+that.popupData.line.id, {salesRequest: that.popupData.line, campaign: that.campaign})	
	    		 .then( response => 
	             {
	             	//alert( JSON.stringify(response.data));
	             	that.$set( that.campaign, 'name', response.data);
	             }).catch(e => {
	                    showError( that.$toast, "loading "+name, e);
	             });  

	 },
	 open(popupData) {
	    this.popupData = popupData;
		let that = this;
		
		HTTP.put( opsAPI+"/prepareCampaign/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+that.popupData.line.salesUnitId+"/"+sessionStorage.userId+"/"+this.popupData.line.id, this.popupData.line)	
		 .then( response => 
         {
         	//alert( JSON.stringify(response.data));
         	that.$set( that.popupData, 'line', response.data.salesRequest);
         	that.campaign = response.data.campaign;
         	that.campaignStatus = response.data.campaignStatus.map( p => {return {id: p.id, label: p.name, visible: true};});
         	that.campaignTypes = response.data.campaignTypes.map( p => {return {id: p.id, label: p.name, visible: true};});
         	that.billingParams = response.data.billingParams.map( p => {return {id: p.id, label: p.name, visible: true};});
			that.taxSettings = response.data.taxSettings.map( p => {return {id: p.id, label: p.name, visible: true};});
         	that.deals = response.data.deals.map( p => {return {id: p.id, label: p.name, visible: true};});
			that.products = response.data.products.map( p => {return {id: p.id, label: p.name, visible: true};});
			if ( !that.campaign.dealId)
			{
				that.deals.push( {id: null, label: 'no deal', visible: true})
			}
			that.products.push( {id: null, label: 'no product', visible: true})
         	that.currency = response.data.currency;
         	that.$modal.show(that.name);
			that.$forceUpdate();
         }).catch(e => {
                showError( that.$toast, "loading "+name, e);
         });  
		
	 	
	 	

	 },
     getTableStyle() {
        
     	//return "overflow-y: scroll; height: calc(60vH - 104px)";
     	return "overflow-y: auto; height: calc("+this.height+"vH - 104px)";
     }
	 
  },
  watch: {

  },
  created() {
	initAppMode();
  }
}
</script>
<style scoped >

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.hTable {
  table-layout: fixed;
  width: 100%;;
}


.tdSmall {
  overflow: hidden;
  width: 5%;
}
.tdLarge {
  width: 25%;
  overflow: hidden;
}
.tdXLarge {
  width: 40%;
  overflow: hidden;
}
.appLeft {
	display:  inline-table; 
	text-align: right;
	width: 100%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 50%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #ffa;
    padding: 0pt 0pt 0pt;
 }
.history {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 400 !important;
}
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
.mainContent {
  position: relative;
  height: 100% !important;
  
  float: top;
} 
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
}
.btn-small {
	height: 14pt;
}
.btn-save {
	background-color: #080 !important;
    color: #fff !important;
}
.button {
    //background-color: white;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(0.7em - 1px);
    padding-left: .7em;
    padding-right: .7em;
    padding-top: calc(0.7em - 1px);
    text-align: center;
    white-space: nowrap;
    font-size: 10pt;
}
.button:hover {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    border: 1pt outset grey;
    background-color: #e8f0e8;
}
.modal-wrapper {

  vertical-align: middle;
}
td { 
    padding-right: 8px;
}
.modal-container {

  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  //background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  padding: 5pt;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}
td { font-size: 14px; padding-top: 4pt; }
.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
